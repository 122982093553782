export const getUTMData = (fullUrl) => {
    const url = new URL(fullUrl);

    // Extract UTM parameters from the query string
    const utmData = {
        utm_source: url.searchParams.get('utm_source') || '',
        utm_medium: url.searchParams.get('utm_medium') || '',
        utm_campaign: url.searchParams.get('utm_campaign') || '',
        utm_term: url.searchParams.get('utm_term') || '',
        utm_content: url.searchParams.get('utm_content') || '',
    };

    return utmData;
};