import axios from "axios";
import config from '../../config/config.json';

export const useFetchCampaignData = () => {


    const fetchCampaignData = async () => {
        try{
            let url = sessionStorage.getItem('entryUrl');
            if(!url){
                url = window.location.href;
                sessionStorage.setItem('entryUrl',url);
                await axios.post(config.REACT_APP_API_MAIN_ROUTE + '/campaign/retrieve',{
                    entryUrl : url
                });
                return;
            }
        } catch(e){
            console.log(e);
            return;
        }
    };


    return {fetchCampaignData};
};