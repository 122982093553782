import { useContext } from "react"
import { SessionContext } from "../../contexts/SessionContext";

export const useSessionContext = () => {
    const context = useContext(SessionContext);
    if(!context){
        throw Error('useSessionContext must be used inside an SessionContext Provider ')
    }
    return context;

}