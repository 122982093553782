import { useState } from "react"
import { useAuthContext } from "../../contextHooks/useAuthContext";
import axios from "axios";

import config from '../../../config/config.json';

export const useUserAPI = () => {
    const {user,dispatch} = useAuthContext();
    const [error,setError] = useState(false);
    const [message,setMessage] = useState("");
    const [userDeleted,setUserDeleted] = useState(false);

    // Functions

    /**
     * Update The User
     * @param {*string} id
     * @param {*Object} obj
     */
    const updateUser = async (id,obj) => {
        try{
            await axios.put(config.REACT_APP_API_MAIN_ROUTE + '/users/' + id,
                obj,
                {
                    headers: {
                        Authorization: "Bearer " + user.token
                    }
                }
            )
            dispatch({type:'LOGIN',payload:{...user,username:obj.username}});
        }
        catch(e){
            setError(true);
            setMessage("An error occured " ,e);
        }
    }
    /**
     * Uploads photo
     * @param {*} file 
     */
    const uploadUserPhoto = async ({file}) => {
        try {
            const formData = new FormData();
            formData.append('profilepics', file);
            formData.append('userId',user.userIdMongo);
            await axios.post(config.REACT_APP_API_MAIN_ROUTE + '/profilepics/upload',formData,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: "Bearer " + user.token
                }
            });         
        } catch (error) {
           console.log(error);
        }
    };

    /**
     * Gets User Photo
     */
    const getUserPhoto = async () => {
        try{
            const res = await axios.get(config.REACT_APP_API_MAIN_ROUTE + "/profilepics/" + user.userIdMongo, {
                headers: {
                    Authorization: "Bearer " + user.token
                }
            });
            return res;
        }
        catch(e){
            console.log(e);
            return [];
        }
    }


    /**
     *
     * @param {*string} id The ID
     */
    const deleteUser = async (id,reason="",message="") => {
        try{
            await axios.delete(config.REACT_APP_API_MAIN_ROUTE + '/users/' + id,
                {
                    headers: {
                        Authorization: "Bearer " + user.token
                    },
                    params : {
                        reason:reason,
                        message:message
                    }
                }
            );
            setUserDeleted(true);
        }
        catch(e){
            setError(true);
            setMessage("An error occured " ,e);
        }
    }


    return {updateUser,deleteUser,uploadUserPhoto,getUserPhoto,error,message,userDeleted};

}