import React , {useState} from 'react';
import { Flex, Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons'
import styles from './actionPanel.module.css';
import  {useAuthContext} from '../../../hooks/contextHooks/useAuthContext';
import { useProjectContext } from '../../../hooks/contextHooks/useProjectContext';
import { useProjectAPI } from '../../../hooks/apiHooks/projectHooks/useProjectAPI';

export default function ActionPanel({ title, description }) {
    // Hooks
    const {user} = useAuthContext();
    const {pDispatch} = useProjectContext();
    const {createProject} = useProjectAPI();
    const [loading, setLoading] = useState(false);

    // Handlers
    const handleConfirm = async () => {
        try{
            setLoading(true);
            const result = await createProject({user_id:user.userIdMongo});
            pDispatch({type:'TRIGGER'})
            let projectId = result.data._id;
            window.location.href = '/project/' + projectId;
        }
        catch(e){
            console.log(e);
        }
        finally {
            setLoading(false); 
        }
    }

    return (
        <Flex justify='space-between' align='center' gap={10}>
            <div>
                <span className={styles.title}> {title} </span>
                <p className={styles.desc}>{description}</p>
            </div>
            
            {/* No Popconfirm */}
            <Button
                type="secondary"
                className={`${styles.button} custom-button`}
                icon={<PlusCircleOutlined />}
                loading={loading} // Set loading state to true/false based on the value of the loading state
                onClick={handleConfirm} // Call handleConfirm when the button is clicked
            >
                New Project
            </Button>
        </Flex>
    )
}
