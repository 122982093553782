import { createContext, useReducer } from 'react';

export const SessionContext = createContext();

export const sessionReducer = (state,action) => {
    switch(action.type){
        case 'SET_SESSION_DATA':
            return { ...state, sessionData: action.payload }
        default:
            return state;
    }
}

export const SessionContextProvider = ({children}) => {
    const [state,sessionDispatch] = useReducer(sessionReducer, {
        sessionData: {}
    })
    
    return (
        <SessionContext.Provider value={{...state,sessionDispatch}}>
            {children}
        </SessionContext.Provider>
    )
}