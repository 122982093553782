import React, { useState } from 'react'
import Section from '../../common/section/section'
import { Form, Button, Divider, Flex, Alert, Input } from 'antd'
import { GoogleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import styles from './signinform.module.css';
import { LoginIcon } from '../../common/icons/mainIcons/mainIcons';
import { useGoogleLogin } from '@react-oauth/google';
import { useAuthForm } from '../../../hooks/formHooks/useAuthForm';
import { fetchIncreaseSignInStat } from '../../../functions/fetchers/fetchEntryData';
import { fetchLocation } from '../../../functions/fetchers/fetchLocation';
import { getUTMData } from '../../../functions/utm/utmFunctions';
import axios from 'axios';
import {

} from '@ant-design/icons';
import { useSessionContext } from '../../../hooks/contextHooks/useSessionContext';


// Function to fetch user info from Google OAuth2 API
const getGoogleUserInfo = async (accessToken) => {
    try {
        const response = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching Google user info:', error);
    }
};

export default function SigninForm({ popupVisible = false }) {
    // Hooks & Contexts
    const { 
        googleLogin, error, message, 
        emailLogin, messageVerification, timer, 
        sendVerificationCode, buttonAvailable 
    } = useAuthForm();
    const sessionId = sessionStorage.getItem('sessionId') || "";
    const {sessionDispatch} = useSessionContext();

    const gLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            await googleLogin(tokenResponse);
        },
    });
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");

    return (

        <Section className={styles.section}>
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
            >
                <Form.Item>
                    <Flex justify='center' align='center' vertical gap={20}>
                        <LoginIcon />
                    </Flex>
                </Form.Item>
                <Form.Item >
                    <Flex justify='center' align='center' vertical>

                        <span className={styles.loginTitle}> Welcome to Decobot </span>
                        <span className={styles.loginSubTitle}> Log in  </span>
                        {/*  or register with your email */}
                    </Flex>
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        size='large'
                        className={styles.loginButtonGoogle}
                        icon={<GoogleOutlined />}
                        onClick={async () => {
                            // 'accessToken' is the OAuth 2.0 access token
                            if (window.location.hostname === 'app.decobot.ai') {
                                const utmData = getUTMData(window.location.href);
                                const countryData = await fetchLocation();
                                const referer = document.referrer || "";
                                await fetchIncreaseSignInStat(sessionId, window.location.href, countryData, utmData, 1, 0,referer);
                            }
                            gLogin();
                        }}
                    >
                        Continue with Google
                    </Button>
                </Form.Item>



                <Divider style={{ margin: 20 }} />
                <Form.Item>
                    <Input placeholder='E-mail' className={styles.loginButton} onChange={(e) => setEmail(e.target.value)} />
                </Form.Item>
                {messageVerification && (
                    <Form.Item>
                        <Input placeholder='Code' className={styles.loginButton} onChange={(e) => setCode(e.target.value)} />
                    </Form.Item>
                )}
                {messageVerification && timer >= 0 && (
                    <Form.Item>
                        <Alert message={"Remaining Seconds: " + timer} type={timer > 0 ? 'warning' : 'error'} />
                        {timer === 0 && (<Button type="default" style={{ marginTop: 5 }} onClick={() => sendVerificationCode(email)}> Refresh </Button>)}
                    </Form.Item>
                )}
                <Form.Item>
                    <Button type="default" className={styles.loginButton} onClick={async () => {
                        // 'accessToken' is the OAuth 2.0 access token
                        if (window.location.hostname === 'app.decobot.ai') {
                            const countryData = await fetchLocation();
                            const utmData = getUTMData(window.location.href);
                            const referer = document.referrer || "";
                            const session = await fetchIncreaseSignInStat(sessionId, window.location.href, countryData, utmData, 0, 1, email,referer);
                            sessionDispatch({type:'SET_SESSION_DATA',payload:session?.data?.data});
                        }
                        emailLogin(email, code);
                    }} disabled={!buttonAvailable}>
                        Continue
                    </Button>
                </Form.Item>
                {popupVisible && <Alert

                    style={{
                        width: 300,
                        display: 'flex',
                        justifyContent: 'center',
                    }}

                    message={
                        <Flex align='flex-start'>
                            <Flex vertical gap={8} align='center' justify='center'>
                                <div style={{
                                    display:'flex',
                                    marginRight:5,
                                    alignItems:'center'
                                }}>
                                    <InfoCircleOutlined style={{ 
                                        fontSize: 19, color: 'blue',  marginRight:5,
                                        
                                    }} />
                                    <span style={{ fontSize: 14, textAlign: 'center' ,}}>
                                        Decobot Mobile Launching Soon!
                                    </span>
                                </div>
                                <span style={{ fontSize: 13, textAlign: 'center' }}>
                                    We're bringing Decobot to mobile devices soon.
                                    Stay tuned for updates!
                                </span>
                            </Flex>

                        </Flex>

                    }
                />}

                {error && (<Alert message={message} type='error' style={{
                    maxWidth:300
                }}/>)}
            </Form>
        </Section>
    )
}
