import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './item-menu.module.css';
import { Button, Divider, Flex, Radio, Tooltip } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { useDragContext } from '../../contexts/DragContext';
import SearchBar from '../common/input/searchbar';
import Section from '../common/section/section';
import { useProjectAPI } from '../../hooks/apiHooks/projectHooks/useProjectAPI';
import { useDebounce } from '../../hooks/asyncHooks/useDebounce';
import { MenuBackIcon, MenuHomeIcon } from '../common/icons/mainIcons/mainIcons';



const Menu = ({ title, rightTitle = "", category, currentCategory, icon, isBack, leftPanelOpen, ...props }) => {
    if (leftPanelOpen) {
        if (!isBack && category !== "") {
            // category === currentCategory add this for category based menu

            if (true) {
                return (
                    <div className={styles.menu} {...props}>
                        {isBack && <MenuBackIcon style={{ height:10,width:10}}/>}
                        {/*<div className={styles.menuIcon}>{icon}</div>*/}
                        <div className={styles.menuTitle}>{title}</div>
                        <div className={styles.rightTitle}>{rightTitle}</div>
                        {!isBack && <RightOutlined style={{ height:10,width:10}}/>}
                    </div>
                );
            }
        }
        else {
            return (
                <div className={styles.menu} {...props}>
                    {isBack && (
                        <>
                            <MenuBackIcon style={{ height:10,width:10}} />
                            <div className={styles.menuTitle}>{title}</div>
                            <div className={styles.rightTitle}>{rightTitle}</div>
                        </>
                    )}
                    {!isBack && (
                        <>

                            <div className={styles.menuIcon}>{icon}</div>
                            <div className={styles.menuTitle}>{title}</div>
                            <RightOutlined style={{ height:10,width:10}}/>
                        </>
                    )}
                </div>
            );
        }
    }
    else {
        return (
            <div className={styles.menu} {...props}>
                {(isBack && !leftPanelOpen) && (
                    <>
                        <MenuBackIcon style={{ height:10,width:10}} />
                        {/*<div className={styles.menuTitle}>{title}</div>*/}
                        <div className={styles.menuTitle}>{rightTitle}</div>
                    </>
                )}
                {!isBack && (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Tooltip placement='right' title={<div className={styles.menuTitle}>{title}</div>}>
                            <Section style={{
                                backgroundColor: '#C8C8C81A',
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'center',
                                padding: '0.6vh 0.4vw'
                            }}>
                                <div className={styles.menuIcon}>{icon}</div>
                            </Section>
                        </Tooltip>
                    </div>
                )}
            </div>
        );
    }
};

const BackMenuButton = ({ path, setPath, leftPanelOpen }) => {
    if (leftPanelOpen) {
        return (
            <Menu key={-1} title={path[path.length - 2]?.title ?? "Back"} rightTitle={path[path.length - 1]?.title ?? ""} onClick={() => setPath((prev) => prev.slice(0, -1))} isBack={true} />
        )
    }
    else {
        return (
            <div className={styles.menu}
                style={{
                }}
                onClick={() => setPath((prev) => prev.slice(0, -1))}
            >
                <Section style={{
                    backgroundColor: '#C8C8C81A',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    padding: '0.42vw'
                }}>
                    <Tooltip placement='right' title={path[path.length - 2]?.title ?? "Back"}>
                        <LeftOutlined />
                    </Tooltip>
                </Section>

            </div>

        )
    }

}

const ProfileSection = ({ projectId, projectName = "Project Name", leftPanelOpen }) => {
    const { updateProject } = useProjectAPI();
    const [name, setName] = useState(projectName);
    const [nonPendingName, setNonPendingName] = useState(projectName);


    const handleRenameSave = useDebounce(async (value) => {
        await updateProject(projectId, { title: value });
        setName(value);
    }, 250);

    const handleInputChange = async (value) => {
        setNonPendingName(value);
    };


    useEffect(() => {
        const handleBeforeUnload = async (event) => {
            // Prevent the default behavior (confirmation dialog)
            //event.preventDefault();

            await updateProject(projectId, {
                title: nonPendingName !== "" ? nonPendingName : projectName
            });
        };

        // Add event listener for beforeunload
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Remove event listener when component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    });

    if (leftPanelOpen) {
        return (
            <Section style={{
                backgroundColor: '#C8C8C81A'
            }} className={styles.profileMenu}>
                <Flex justify='space-between' align='center'>
                    <Button 
                        onClick={() => {
                            window.history.back();
                        }}
                        type='text'
                        icon={<MenuHomeIcon/>}
                        style={{
                            marginRight: 6,
                            align: 'center'
                        }}
                    />
                    <input style={{
                        backgroundColor: '#C8C8C81A',
                    }}
                        placeholder={projectName}
                        onChange={(e) => {
                            handleInputChange(e.target.value);
                            handleRenameSave(e.target.value);
                        }}
                        className={styles.profileMenuInput}
                    />
                </Flex>
            </Section>
        )
    }
    else {
        return (
            <Section style={{
                backgroundColor: '#C8C8C81A',
                height: '1vh', width: '0.5vw',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Button type='text' icon={<MenuHomeIcon/>} onClick={() => window.history.back()}/>
            </Section>
        )
    }

}

const RoomtypeRadio = ({ title, value, selected, onRadio, leftPanelOpen, ...props }) => {
    if (leftPanelOpen) {
        return (
            <div className={styles.menu} {...props}>
                <label className={styles.container}>
                    <Flex gap={3} align-items='center'>
                        <Radio onChange={() => onRadio(value)} checked={value === selected} />
                        {title}
                    </Flex>
                </label>
            </div>
        )
    }
    else {
        return (
            <div className={styles.menu} {...props}>
                <label className={styles.container}>
                    <Tooltip placement='right' title={title}>
                        <Radio onChange={() => onRadio(value)} checked={value === selected} />
                    </Tooltip>
                </label>
            </div>
        )
    }

}

const Item = ({ item, icon, title, description, handleItemClick, handleItemDrag, handleItemHover, leftPanelOpen }) => {

    const { startDrag, draggedItem, endDrag } = useDragContext();

    const handleDragStart = (event, item) => {
        startDrag(item);
        const ghostElement = document.getElementById('ghost_' + item.title);
        if (ghostElement) {
            event.dataTransfer.setDragImage(ghostElement, 25, 25);
        }

        const whiteboardContent = document.getElementById('whiteboard-content');
        if (whiteboardContent) {
            whiteboardContent.addEventListener('dragover', handleDragOver);
        }


    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        handleItemDrag();
        endDrag();

        const whiteboardContent = document.getElementById('whiteboard-content');
        if (whiteboardContent) {
            whiteboardContent.removeEventListener('dragover', handleDragOver);
        }

    };

    if (leftPanelOpen) {
        return (
            <div
                className={styles.item}
                onDoubleClick={handleItemClick}
                onDragStart={(e) => handleDragStart(e, item)}
                onDragOver={handleDragOver}
                onDragEnd={handleDrop}
                draggable
            >
                <div id={'ghost_' + item.title} className={styles.itemIcon}>{icon}</div>
                <div className={styles.itemInfo}>

                    <div className={styles.itemTitle}>{title}</div>
                    {item.path !== "" && item.path && <div className={styles.itemDescription}>{item.path.split(">").slice(0, -1).join('>')}</div>}
                    <div className={styles.itemDescription}>{description}</div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div
                className={styles.item}
                onDoubleClick={handleItemClick}
                onDragStart={(e) => handleDragStart(e, item)}
                onDragOver={handleDragOver}
                onDragEnd={handleDrop}
                draggable
            >
                <Tooltip placement='right' title={<div className={styles.itemTitle}>{title}</div>}>
                    <div id={'ghost_' + item.title} className={styles.itemIconClosed}>{icon}</div>
                </Tooltip>


            </div>
        );
    }

};

const ItemMenu = ({
    projectId,
    projectName,
    items,
    path = [],
    setPath,
    handleItemClick,
    handleItemDrag,
    leftPanelOpen,
    
}) => {
    // Hooks
    const [currentRoomType, setCurrentRoomType] = useState("Living Room");
    const [query, setQuery] = useState("");
    const scrollableContentRef = useRef(null);
    const [scrollPositions, setScrollPositions] = useState({});

    const currentPath = [...path];
    let currentItems = items;

    const getItemsByQuery = (items, query) => {
        let matchingItems = [];

        let recursive = (items, query, currentPath) => {
            if (!items || items.length === 0) {
                // Base case: No items to search
                return;
            }

            items.forEach(item => {
                if (item.type === 'item' && item.title.toLowerCase().includes(query.toLowerCase())) {
                    matchingItems.push({ ...item, path: currentPath });
                }
                if (item.type === "menu" && item.children) {
                    // Recursive case: Search within children
                    recursive(item.children, query, currentPath + item.title + ">");
                }
            });
        }

        recursive(items, query, "");
        return matchingItems.sort((a, b) => {
            const regex = /(\D+)(\d*)/; // Matches non-digits followed by digits
            const [, alphaA, numA] = a.title.match(regex);
            const [, alphaB, numB] = b.title.match(regex);

            // First, compare the alphabetical parts
            const alphaComparison = alphaA.localeCompare(alphaB);
            if (alphaComparison !== 0) {
                return alphaComparison;
            }

            const numberA = numA ? parseInt(numA, 10) : 0;
            const numberB = numB ? parseInt(numB, 10) : 0;
            return numberA - numberB;
        });


    }


    while (currentPath.length > 0) {
        const p = currentPath.shift();
        currentItems = currentItems.find(item => item.key === p.key).children;
        currentItems = currentItems.sort((a, b) => {
            const regex = /(\D+)(\d*)/; // Matches non-digits followed by digits
            const [, alphaA, numA] = a.title.match(regex);
            const [, alphaB, numB] = b.title.match(regex);

            // First, compare the alphabetical parts
            const alphaComparison = alphaA.localeCompare(alphaB);
            if (alphaComparison !== 0) {
                return alphaComparison;
            }

            const numberA = numA ? parseInt(numA, 10) : 0;
            const numberB = numB ? parseInt(numB, 10) : 0;
            return numberA - numberB;
        })
    }

    const handleRadio = (value) => {
        setCurrentRoomType(value);
    };

    const memoizedCurrentItems = useMemo(() => {
        return currentItems.filter((item) => item.title.toLowerCase().includes(query.toLowerCase()));
    }, [currentItems, query]);


    const memoizedSearchItems = useMemo(() => {
        return getItemsByQuery(items, query);
    }, [query])

    useEffect(() => {
        setQuery("");
    }, [currentItems]);

    useEffect(() => {
        if (scrollableContentRef.current) {
            const currentPathKey = JSON.stringify(path);
            if (scrollPositions[currentPathKey] !== undefined) {
                // Restore scroll position for the current path
                scrollableContentRef.current.scrollTop = scrollPositions[currentPathKey];
            } else {
                // If no stored position, scroll to top
                scrollableContentRef.current.scrollTop = 0;
            }
        }
    }, [path, leftPanelOpen]);

    const handleScroll = () => {
        if (scrollableContentRef.current) {
            const currentPathKey = JSON.stringify(path);
            // Save scroll position
            setScrollPositions({
                ...scrollPositions,
                [currentPathKey]: scrollableContentRef.current.scrollTop,
            });
        }
    };

    const handlePathChange = (newPath) => {
        if (scrollableContentRef.current) {
            const currentPathKey = JSON.stringify(path);
            // Save scroll position before path changes
            setScrollPositions({
                ...scrollPositions,
                [currentPathKey]: scrollableContentRef.current.scrollTop,
            });
        }
        setPath(newPath);
    };

    const color = path.length > 0 ? '#3E3E3E' : '#272CA1';

    return (
        <div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                marginBottom: '8px',
            }}>
                <ProfileSection projectId={projectId} projectName={projectName} leftPanelOpen={leftPanelOpen}/>
                {!leftPanelOpen && <Divider style={{marginTop:'0.1vh',marginBottom:'0.2vh'}}/>}
                {leftPanelOpen && <SearchBar query={query} setQuery={setQuery} />}
                {path.length > 0 && (
                    <div style={{
                        paddingBottom: '10px',
                        borderBottom: '0.5px solid #f0f0f0'
                    }}>
                        <BackMenuButton path={path} setPath={handlePathChange} leftPanelOpen={leftPanelOpen} />
                    </div>

                )}
            </div>
            <div
                className={leftPanelOpen ? styles.container : styles.containerClosed}
                ref={scrollableContentRef}
                onScroll={handleScroll}
                style={{
                    overflow: 'auto',
                    height: '79vh',
                    scrollbarWidth: 'none',
                }}
            >
                {query === "" && memoizedCurrentItems.map(item => {
                    if (item.type === 'menu') {
                        return (
                            <Menu
                                key={item.key}
                                category={item.category ?? ""}
                                currentCategory={currentRoomType}
                                title={item.title}
                                icon={item.icon}
                                onClick={() => handlePathChange([...path, { key: item.key, title: item.title }])}
                                isBack={false}
                                leftPanelOpen={leftPanelOpen}
                                style={{
                                    color: color
                                }}
                            />
                        );
                    }
                    if (item.type === 'radio') {
                        return (
                            <RoomtypeRadio
                                key={item.key}
                                title={item.title}
                                value={item.value}
                                selected={currentRoomType}
                                onRadio={handleRadio}
                                leftPanelOpen={leftPanelOpen}
                            />
                        );
                    }
                    if (item.type === 'item') {
                        return (
                            <Item
                                key={item.key}
                                item={item}
                                icon={item.icon}
                                title={item.title}
                                description={item.description}
                                handleItemClick={() => handleItemClick(item)}
                                handleItemDrag={() => handleItemDrag(item)}
                                leftPanelOpen={leftPanelOpen}
                            //handleItemHover = {handleHoverItem}
                            />
                        );
                    }
                })}
                {query !== "" && memoizedSearchItems.map(item => {
                    return (
                        <Item
                            key={item.key}
                            item={item}
                            icon={item.icon}
                            title={item.title}
                            description={item.description}
                            handleItemClick={() => handleItemClick(item)}
                            handleItemDrag={() => handleItemDrag(item)}
                            leftPanelOpen={leftPanelOpen}
                        //handleItemHover = {handleHoverItem}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default ItemMenu;