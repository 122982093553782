import axios from "axios";
import config from '../../config/config.json';


export const getSessionData = async (sessionId) => {
     try {
       const res = await axios.get(config.REACT_APP_API_MAIN_ROUTE + `/entry/${sessionId}`);
       return res;
    } catch (error) {
        console.log('Error fetching the session:', error);
        return {};
    }
};

export const fetchEntryData = async (sessionId, url, countryData, utmData = {}) => {
    try {
        const referrer = document.referrer;
        const res = await axios.post(config.REACT_APP_API_MAIN_ROUTE + "/entry", {
            session_id: sessionId,
            url: url,
            country_data: countryData,
            utm_data: utmData,
            referrer_url: referrer || ""
        }, {
            headers : {
                'Referrer-Policy' : 'no-referrer-when-downgrade'
            }
        });
        if (res) {
            return {
                success: true
            }
        }
        else {
            return {
                success: false
            }
        }
    } catch (error) {
        console.log('Error fetching the location:', error);
        return {};
    }
};

export const fetchIncreaseSignInStat = async (sessionId, url, countryData, utmData = {}, googleClickCount, continueClickCount, email = "",referrerUrl="") => {
    try {
        const res = await axios.put(config.REACT_APP_API_MAIN_ROUTE + "/entry", {
            session_id: sessionId,
            url: url,
            country_data: countryData,
            utm_data: utmData,
            google_click_count: googleClickCount,
            continue_click_count: continueClickCount,
            email: email
        });
        if (res) {
            return res;
        }
        else {
            return {
                success: false
            }
        }
    } catch (error) {
        console.log('Error fetching the location:', error);
        return {};
    }
}


export const updateSession = async (sessionId, email="",rateLimit=0) => {
    try {
        const res = await axios.put(config.REACT_APP_API_MAIN_ROUTE + `/entry/${sessionId}`, {
            email: email
        });
        if (res) {
            return {
                success: true
            }
        }
        else {
            return {
                success: false
            }
        }
    } catch (error) {
        console.log('Error fetching the session user data:', error);
        return {};
    }
}