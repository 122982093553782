import axios from "axios";
import config from '../../config/config.json'

export const fetchLocation = async () => {
    try {
        const response = await axios.get(`https://ipinfo.io/json?token=${config.REACT_APP_IP_TOKEN}`);
        let result = {
            ip: response.data.ip,
            country: response.data.country,
            city: response.data.city,
            region: response.data.region,
            timezone: response.data.timezone,
            loc: response.data.loc,
            postal:response.data.postal,
            hostname:response.data.hostname
        };
        return result;
    } catch (error) {
        console.log('Error fetching the location:', error);
        return {};
    }
};