export const generateId = () => {
    return btoa(Math.random()).substring(0, 12);
}

export const convertMetersToFeet = (cm) => {
    const feet = cm / 30.48; // 1 foot = 30.48 centimeters
    return feet.toFixed(2);
}

export const convertToFeet = (dimensions) => {
    const meterToFeet = 3.28084;

    // Extract the dimensions from the string
    const matches = dimensions.match(/[\d.]+/g);

    if (!matches || matches.length !== 3) {
        // Handle cases where the input doesn't match the expected pattern
        return dimensions;
    }

    // Extract the dimensions from the string
    const [depth, width, height] = dimensions.match(/[\d.]+/g).map(Number);

    // Convert each dimension from meters to feet
    const depthInFeet = (depth * meterToFeet).toFixed(2);
    const widthInFeet = (width * meterToFeet).toFixed(2);
    const heightInFeet = (height * meterToFeet).toFixed(2);

    // Return the converted dimensions in the desired format
    return `${depthInFeet} ft x ${widthInFeet} ft x ${heightInFeet} ft (DWH)`;
}

export const convertToCentimeters = (dimensions) => {
    const meterToCentimeter = 100;

    // Extract the dimensions from the string
    const matches = dimensions.match(/[\d.]+/g);

    if (!matches || matches.length !== 3) {
        // Handle cases where the input doesn't match the expected pattern
        return dimensions;
    }

    // Extract the dimensions from the string
    const [depth, width, height] = matches.map(Number);

    // Convert each dimension from meters to centimeters
    const depthInCentimeters = (depth * meterToCentimeter).toFixed(2);
    const widthInCentimeters = (width * meterToCentimeter).toFixed(2);
    const heightInCentimeters = (height * meterToCentimeter).toFixed(2);

    // Return the converted dimensions in the desired format
    return `${depthInCentimeters} cm x ${widthInCentimeters} cm x ${heightInCentimeters} cm (DWH)`;
};


export const isSubGroup = (groupedItems, newGroup) => {

    for(let group of groupedItems){
        if (group.every(item => newGroup.includes(item))) {
            return {
                val:true,
                biggerGroup: group.length < newGroup.length ? newGroup.sort().join("") : group.sort().join(""),
                smallerGroup:  group.length < newGroup.length ? group.sort().join("") : newGroup.sort().join("")
            };
        }
    }
    return {
        val: false,
        biggerGroup:[],
        smallerGroup:[]
    }
};

export const getCombinedBoundingBox = (nodes) => {
    if (!nodes.length) return null;

    const boundingBoxes = nodes.map(node => node.getClientRect());

    const left = Math.min(...boundingBoxes.map(box => box.x));
    const top = Math.min(...boundingBoxes.map(box => box.y));
    const right = Math.max(...boundingBoxes.map(box => box.x + box.width));
    const bottom = Math.max(...boundingBoxes.map(box => box.y + box.height));

    const width = right - left;
    const height = bottom - top;

    return { left, top, right, bottom, width, height };
};

/*
export const calculatePolarAngle = (x, y, centerX, centerY) => {
    return Math.atan2(y - centerY, x - centerX);
}
*/

/*

function calculateOppositeCorner(x, y, width, height, rotation) {
    const rotationRad = rotation * (Math.PI / 180);
    const topX = x + height * Math.sin(rotationRad);
    const topY = y - height * Math.cos(rotationRad);

    return { x: topX, y: topY };
}

function calculateRightCorner(x, y, width, height, rotation) {
    const rotationRad = rotation * (Math.PI / 180);
    const rightX = x + width * Math.cos(rotationRad);
    const rightY = y + width * Math.sin(rotationRad);
    return { x: rightX, y: rightY };
}
function calculateLeftCorner(x, y, width, height, rotation) {
    const rotationRad = rotation * (Math.PI / 180);
    const rightX = x - width * Math.cos(rotationRad);
    const rightY = y - width * Math.sin(rotationRad);
    return { x: rightX, y: rightY };
}
function determineItemSide(wallStartX, wallStartY, wallEndX, wallEndY, itemX, itemY, rotation, width, height) {
    const rotationRad = rotation * (Math.PI / 180);

    const itemCornerX = itemX + width * Math.cos(rotationRad);
    const itemCornerY = itemY + width * Math.sin(rotationRad);

    const wallDirectionX = wallEndX - wallStartX;
    const wallDirectionY = wallEndY - wallStartY;

    const vectorToItemX = itemCornerX - wallStartX;
    const vectorToItemY = itemCornerY - wallStartY;

    const dotProduct = wallDirectionX * vectorToItemY - wallDirectionY * vectorToItemX;

    if (dotProduct > 0) {
        return 1;
    } else {
        return 0;
    }
}
*/
